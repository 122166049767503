import request from '@/utils/request'

export function queryCreditsRecord(query) {
    return request({
        url: 'creditsRecord/queryCreditsRecord',
        method: 'get',
        params: query
    })
}

export function updateUserCredits(credits) {
    return request({
        url: 'user/updateUserCredits',
        method: 'put',
        data: credits
    })
}