<template>
  <div class="app-container">
    <div>
      <el-page-header @back="goBack" content="详情页面" />
    </div>
    <el-form
      ref="user"
      :model="user"
      label-width="90px"
      style="width: 100%; height: 200px"
    >
      <h3 style="text-align: left">用户信息</h3>
      <el-form-item label="用户名称：" style="text-align: left">
        <span>{{ user.realname }}</span>
        <!--                <el-input v-model="user.realname"></el-input>-->
      </el-form-item>
      <el-form-item label="积分：" style="text-align: left">
        <span>{{ user.credits }}</span>
        <!-- <el-button type="primary" size="mini" @click="updateCredit" plain style="margin-left: 20px;">修改</el-button> -->
      </el-form-item>
    </el-form>

    <el-table ref="tables" :data="list" v-loading="loading">
      <el-table-column label="编号" align="center" prop="id" width="80" />
      <el-table-column label="积分增减" align="center" prop="num" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.num }}.00</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作员"
        align="center"
        prop="operator"
        width="200"
      />
      <el-table-column
        label="操作时间"
        align="center"
        prop="createtime"
        width="200"
      />
      <el-table-column
        label="备注"
        align="center"
        prop="remark"
        :show-overflow-tooltip="true"
      />
    </el-table>

    <!--        分页-->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="queryCreditsRecord"
    />

    <!-- 添加或修改对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form
        ref="creditsForm"
        :model="creditsForm"
        :rules="rules"
        label-width="80px"
        style="width: 100%; height: 100%"
      >
        <el-form-item label="积分操作" prop="credit">
          <el-input
            v-model="creditsForm.credit"
            oninput="value=value.replace(/[^-\d]/g,'')"
            controls-position="right"
            :min="0"
            maxlength="5"
            autocomplete="off"
          />
          <span style="color: #999999"
            >输入500,则标识增加500;输入-500则表示减少500</span
          >
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="creditsForm.remark"
            placeholder="请输入备注"
            maxlength="100"
            autocomplete="off"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryCreditsRecord, updateUserCredits } from "@/api/user/credits";
export default {
  name: "Credits",
  data() {
    return {
      // 总条数
      total: 0,
      loading: true,
      open: false,
      title: "积分增减",
      // 列表数据
      list: [],
      user: {},
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        // 用户ID
        userId: undefined,
      },
      creditsForm: {
        userId: undefined,
        credit: undefined,
        remark: undefined,
      },
      rules: {
        credit: [
          { required: true, message: "积分操作不能为空", trigger: "blur" },
        ],
        remark: [{ required: true, message: "备注不能为空", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getUserId();
    this.queryCreditsRecord();
  },
  methods: {
    getUserId() {
      this.queryParams.userId = this.$route.query.userId;
    },
    queryCreditsRecord() {
      if (this.queryParams.userId !== undefined) {
        this.loading = true;
        queryCreditsRecord(this.queryParams).then((res) => {
          // console.log(res);
          // 获取数据
          this.list = res.data.data;
          // 获取用户信息
          this.user = res.data.user;
          // 获取总条数
          this.total = res.data.pageBean.count;
          this.loading = false;
        });
      } else {
        this.$router.push({ path: "/Home" });
      }
    },
    updateCredit() {
      this.resetForm("creditsForm");
      this.open = true;
    },
    // 积分增减
    submitForm() {
      this.$refs["creditsForm"].validate((valid) => {
        if (valid) {
          let data = {
            uid: this.queryParams.userId,
            num: this.creditsForm.credit,
            remark: this.creditsForm.remark,
          };
          updateUserCredits(data).then((res) => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.open = false;
            this.queryCreditsRecord();
          });
        }
      });
    },
    cancel() {
      this.open = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px;
}
</style>